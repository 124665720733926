<script setup lang="ts">
import type { ModelRef } from 'vue';

defineProps<{
  errors: FormNotificationErrors;
}>();

const form: ModelRef<DefaultNotificationForm> = defineModel('form', {
  type: Object,
  required: true,
});

function handleResetIsRecurring() {
  form.value.recurring_type = undefined;
  form.value.recurring_days_of_week = undefined;
  form.value.recurring_interval = undefined;
  form.value.recurring_calendar_days = undefined;
}

function handleResetRecurringType() {
  form.value.recurring_days_of_week = undefined;
  form.value.recurring_interval = undefined;
  form.value.recurring_calendar_days = undefined;

  if (form.value.recurring_type === 'calendar_days') {
    form.value.start_date = undefined;
    form.value.end_date = undefined;
  }
}
</script>

<template>
  <div class="space-y-1.5">
    <h2 class="text-sm font-medium text-gray-iron-700">Typ</h2>

    <div class="flex flex-wrap gap-3 text-sm font-normal text-gray-iron-700">
      <PrimeRadioButton
        v-model="form.is_recurring"
        inputId="not_recurring"
        :value="false"
        @update:modelValue="handleResetIsRecurring"
      />
      <label for="not_recurring"> Jednorazowe </label>

      <PrimeRadioButton
        v-model="form.is_recurring"
        inputId="reccuring"
        :value="true"
        @update:modelValue="handleResetIsRecurring"
      />
      <label for="reccuring"> Cykliczne </label>
    </div>

    <small class="text-left text-xs text-red-600">
      {{ errors.is_recurring }}
    </small>
  </div>

  <div v-if="form.is_recurring" class="space-y-1.5">
    <h2 class="text-sm font-medium text-gray-iron-700">Powtarzaj</h2>

    <div class="flex flex-wrap gap-4 text-sm text-gray-iron-700">
      <PrimeRadioButton
        v-model="form.recurring_type"
        inputId="choice_day"
        value="choice_day"
        @update:modelValue="handleResetRecurringType"
      />
      <label for="choice_day">Wybrane dni</label>

      <PrimeRadioButton
        v-model="form.recurring_type"
        inputId="quantity_day"
        value="quantity_day"
        @update:modelValue="handleResetRecurringType"
      />
      <label for="quantity_day"> Ilość dni </label>

      <PrimeRadioButton
        v-model="form.recurring_type"
        inputId="calendar_days"
        value="calendar_days"
        @update:modelValue="handleResetRecurringType"
      />
      <label for="calendar_days"> Wybierz w kalendarzu </label>
    </div>

    <small class="text-left text-xs text-red-600">
      {{ errors.recurring_type }}
    </small>
  </div>

  <div v-if="form.recurring_type === 'calendar_days'" class="space-y-1.5">
    <InteractiveDaysCalendar
      v-model:selected-days="form.recurring_calendar_days"
    />

    <small class="text-left text-xs text-red-600">
      {{ errors.recurring_calendar_days }}
    </small>
  </div>

  <div v-if="form.recurring_type === 'choice_day'" class="space-y-1.5">
    <div class="flex items-center justify-between text-sm text-gray-iron-700">
      <div
        :key="day"
        v-for="(day, index) in ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd']"
        class="flex items-center gap-2"
      >
        <PrimeCheckbox
          v-model="form.recurring_days_of_week"
          :inputId="day"
          :value="index + 1"
        />
        <label :for="day">
          {{ day }}
        </label>
      </div>
    </div>

    <small class="text-left text-xs text-red-600">
      {{ errors.recurring_days_of_week }}
    </small>
  </div>

  <DefaultInputNumber
    v-if="form.recurring_type === 'quantity_day'"
    v-model="form.recurring_interval"
    showButtons
    buttonLayout="horizontal"
    :step="1"
    :minFractionDigits="0"
    suffix="dni"
    fluid
    :min="1"
    inputClass="text-center text-sm"
    :error="errors.recurring_interval"
  >
    Co
  </DefaultInputNumber>

  <DefaultDatePicker
    v-if="form.recurring_type !== 'calendar_days'"
    v-model="form.start_date"
    :error="errors.start_date"
    :minDate="new Date()"
  >
    Data rozpoczęcia
  </DefaultDatePicker>

  <DefaultDatePicker
    v-if="form.recurring_type !== 'calendar_days' && form.is_recurring"
    v-model="form.end_date"
    :error="errors.end_date"
    :minDate="form.start_date ? new Date(form.start_date) : new Date()"
  >
    Data zakończenia
  </DefaultDatePicker>

  <DefaultDatePicker
    v-model="form.start_time"
    timeOnly
    :error="errors.start_time"
  >
    O godzinie
  </DefaultDatePicker>
</template>
