import type {
  AddManyNotificationCampaigns,
  AddManyTemplateNotificationCampaigns,
} from '@/utils/api/notificationCampaignsService';
import type { ResponseError } from '@/utils/responseError';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { notificationCampaignsService } from '@/utils/api/notificationCampaignsService';

export function useAddNotificationCampaign(onSuccess?: () => void) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const activeIndex = ref(0);

  const { formRefs, forms, changeType, resetForms, fillForm } =
    useNotificationForms();

  async function handleSubmit(type: 'active' | 'draft' | 'template') {
    for (const form of formRefs.value) {
      await form.validate();
    }

    if (formRefs.value.some((form) => Object.keys(form.errors).length)) {
      return;
    }

    const preparedForms = formRefs.value.map((form, index) => ({
      ...form.form,
      kind_type: forms.value[index].type,
    }));

    handleNotificationFormSubmit(preparedForms, type);
  }

  function addNewForm() {
    forms.value.push({
      type: 'default',
    });

    activeIndex.value = forms.value.length - 1;
  }

  const { mutate, isPending } = useMutation({
    mutationFn: (
      payload:
        | {
            payload: AddManyNotificationCampaigns;
            kind_type: 'active' | 'draft';
          }
        | {
            payload: AddManyTemplateNotificationCampaigns;
            kind_type: 'template';
          },
    ) => {
      if (payload.kind_type === 'template') {
        return notificationCampaignsService.storeTemplateNotificationCampaigns(
          payload.payload as AddManyTemplateNotificationCampaigns,
        );
      }

      return notificationCampaignsService.storeNotificationCampaigns(
        payload.payload as AddManyNotificationCampaigns,
      );
    },
    onSuccess: () => {
      resetForms();
    },
    onError: (error: ResponseError) => {
      if (error.status === 422) {
        toast.add({
          severity: 'error',
          summary: error.message,
          life: 3000,
        });

        return;
      }

      toast.add({
        severity: 'error',
        summary: 'Wystąpił błąd podczas dodawania powiadomienia',
        life: 3000,
      });

      queryClient.invalidateQueries({ queryKey: ['notification-campaigns'] });
    },
  });

  function handleNotificationFormSubmit(
    forms: (DefaultNotificationForm & {
      kind_type: 'default' | 'form' | 'visit';
    })[],
    type: 'active' | 'draft' | 'template',
  ) {
    if (type === 'template') {
      mutate(
        {
          payload: {
            notification_templates: forms.map((form) => ({
              ...form,
            })),
          },
          kind_type: 'template',
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['notification-templates'],
            });

            onSuccess && onSuccess();
          },
        },
      );

      return;
    }

    mutate(
      {
        payload: {
          notification_campaigns: forms.map((form) => ({
            ...form,
            is_active: type === 'active',
          })),
        },
        kind_type: type,
      },
      {
        onSuccess: () => {
          if (type === 'active') {
            queryClient.invalidateQueries({
              queryKey: ['active-notification-campaigns'],
            });
          }

          if (type === 'draft') {
            queryClient.invalidateQueries({
              queryKey: ['draft-notification-campaigns'],
            });
          }

          onSuccess && onSuccess();
        },
      },
    );
  }

  return {
    handleNotificationFormSubmit,
    isPending,
    formRefs,
    forms,
    changeType,
    activeIndex,
    handleSubmit,
    addNewForm,
    fillForm,
    resetForms,
  };
}
