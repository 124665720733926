import { useQuery } from '@tanstack/vue-query';
import { formsService } from '~/utils/api/formsService';

export function useAllFormTemplates() {
  return useQuery({
    queryKey: ['form_all'],
    queryFn: async () => {
      return await formsService.getFormsAll();
    },
  });
}
