import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { notificationTemplatesService } from '@/utils/api/notificationTemplatesService';
import type { NotificationCampaign } from '~/utils/types';

export function useDeleteNotificationTemplate() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const confirm = useConfirm();

  function confirmDeleteNotificationTemplate(id: number) {
    confirm.require({
      message: 'Czy na pewno chcesz usunąć powiadomienie?',
      header: ' ',
      rejectLabel: 'Anuluj',
      rejectProps: {
        label: 'Anuluj',
        severity: 'secondary',
        outlined: true,
        size: 'small',
        rounded: true,
      },
      acceptProps: {
        label: 'Usuń',
        severity: 'danger',
        size: 'small',
        rounded: true,
      },
      accept: () => {
        deleteNotificationTemplate(id);
      },
    });
  }

  const { mutate } = useMutation({
    mutationFn: (notificationTemplate_id: number) =>
      notificationTemplatesService.deleteNotificationTemplate(
        notificationTemplate_id,
      ),
    onError: (error) => {
      console.log(error);

      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas usuwania szablonu',
        life: 3000,
      });

      queryClient.invalidateQueries({ queryKey: ['notification-templates'] });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notification-templates'] });
    },
  });

  function deleteNotificationTemplate(notificationTemplate_id: number) {
    mutate(notificationTemplate_id);
  }

  return {
    confirmDeleteNotificationTemplate,
  };
}
