<script setup lang="ts">
const op = ref();

const newNotificationVisible = ref(false);
const templateNotificationVisible = ref(false);

const selectedTemplate = ref<NotificationCampaign | undefined>(undefined);

function addNewNotification() {
  selectedTemplate.value = undefined;
  newNotificationVisible.value = true;
}

watch(selectedTemplate, () => {
  if (selectedTemplate.value) {
    newNotificationVisible.value = true;
  }
});
</script>

<template>
  <PrimeButton
    class="font-semibold"
    size="small"
    rounded
    @click="(event: MouseEvent) => op.toggle(event)"
  >
    <i class="pi pi-plus" />Dodaj powiadomienie
    <i class="pi pi-angle-down" />
  </PrimeButton>

  <PrimePopover
    class="min-w-52 max-w-52 before:!border-0 after:!border-0"
    ref="op"
  >
    <PrimeButton
      class="w-full !justify-start !font-normal"
      text
      size="small"
      severity="secondary"
      @click="addNewNotification"
    >
      Nowe powiadomienie
    </PrimeButton>

    <PrimeButton
      class="w-full !justify-start !font-normal"
      text
      size="small"
      severity="secondary"
      @click="templateNotificationVisible = true"
    >
      Użyj szablonu
    </PrimeButton>
  </PrimePopover>

  <LazyAddNotificationFromTemplate
    v-if="templateNotificationVisible"
    v-model:visible="templateNotificationVisible"
    v-model:selectedTemplate="selectedTemplate"
  />
  <LazyAddNewNotificationCampaign
    v-model:visible="newNotificationVisible"
    :notification="selectedTemplate"
  />
</template>
