<script setup lang="ts">
const props = defineProps<{
  type: 'active' | 'draft';
}>();

const {
  search,
  data: campaigns,
  isLoading,
  page,
  PER_PAGE,
} = useNotificationCampaigns(props.type);
</script>

<template>
  <div>
    <div class="mb-6 flex items-center justify-between">
      <PrimeIconField>
        <PrimeInputIcon class="pi pi-search" />
        <PrimeInputText v-model="search" placeholder="Szukaj" />
      </PrimeIconField>

      <AddNotificationCampaign />
    </div>

    <NotificationCampaignsTable
      v-if="campaigns?.data?.length"
      :type="type"
      :campaigns="campaigns.data"
    />

    <div v-else-if="!isLoading" class="text-base text-gray-iron-900">
      Nie znaleziono kampanii
    </div>

    <PrimePaginator
      v-if="Number(campaigns?.meta.total) > 10"
      v-model:first="page"
      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      :rows="PER_PAGE"
      :totalRecords="campaigns?.meta.total"
    />
  </div>
</template>
a
