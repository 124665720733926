<script setup lang="ts">
import type { ModelRef } from 'vue';

const type: ModelRef<'default' | 'form' | 'visit'> = defineModel('type', {
  type: String,
  required: true,
});
</script>

<template>
  <div class="space-y-1.5">
    <h2 class="mb-1 text-sm font-medium text-gray-iron-700">Rodzaj</h2>
    <div class="flex flex-wrap items-center gap-3 text-sm text-gray-iron-700">
      <PrimeRadioButton v-model="type" inputId="default" value="default" />
      <label for="default"> Domyślne </label>

      <PrimeRadioButton v-model="type" inputId="form" value="form" />
      <label for="form"> Formularz </label>

      <PrimeRadioButton v-model="type" inputId="visit" value="visit" />
      <label for="visit"> Potwierdzenie wizyty </label>
    </div>
  </div>
</template>
