import { toTypedSchema } from '@vee-validate/zod';
import { formtNotificationSchema } from '~/utils/schemas';
import { useAppForm } from '~/composables/useAppForm';

export type FormFormNotificationErrors = {
  title?: string;
  designation?: string;
  body?: string;
  type?: string;
  form_id?: string;
};

export type FormNotificationForm = {
  title: string;
  designation: string;
  body: string;
  type: 'email' | 'email_push' | 'push';
  form_id: number;
};

export function useFormNotificationCampaignForm() {
  const schema = computed(() => toTypedSchema(formtNotificationSchema));

  const { form, handleSubmit, errors, resetForm, validate } = useAppForm({
    fields: ['title', 'designation', 'body', 'type', 'form_id'],
    validationSchema: schema,
  });

  function handleResetForm() {
    resetForm();
  }

  handleResetForm();

  return {
    form,
    handleSubmit,
    errors,
    resetForm,
    validate,
  };
}
