import type { ResponseError } from '@/utils/responseError';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { notificationCampaignsService } from '@/utils/api/notificationCampaignsService';
import { notificationTemplatesService } from '@/utils/api/notificationTemplatesService';

export function useEditNotificationCampaign(
  formRefs: Ref<any[]>,
  forms: Ref<
    {
      type: 'default' | 'form' | 'visit';
    }[]
  >,
  notification: NotificationCampaign,
  type: 'active' | 'draft' | 'template',
  onSuccess?: () => void,
) {
  const toast = useToast();
  const queryClient = useQueryClient();

  async function handleEdit() {
    for (const form of formRefs.value) {
      await form.validate();
    }

    if (formRefs.value.some((form) => Object.keys(form.errors).length)) {
      return;
    }

    const preparedForms = formRefs.value.map((form, index) => ({
      ...form.form,
      kind_type: forms.value[index].type,
    }));

    handleNotificationFormSubmit(preparedForms);
  }

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: DefaultNotificationForm) => {
      if (type !== 'template') {
        return notificationCampaignsService.editNotificationCampaign(
          {
            ...payload,
            is_active: type === 'active',
          } as DefaultNotificationForm,
          notification.id,
        );
      }

      return notificationTemplatesService.editNotificationCampaign(
        payload as DefaultNotificationForm,
        notification.id,
      );
    },
    onError: (error: ResponseError) => {
      if (error.status === 422) {
        toast.add({
          severity: 'error',
          summary: error.message,
          life: 3000,
        });

        return;
      }

      toast.add({
        severity: 'error',
        summary: 'Wystąpił błąd podczas dodawania powiadomienia',
        life: 3000,
      });

      queryClient.invalidateQueries({ queryKey: ['notification-campaigns'] });
    },
  });

  function handleNotificationFormSubmit(
    forms: (DefaultNotificationForm & {
      kind_type: 'default' | 'form' | 'visit';
    })[],
  ) {
    if (type === 'template') {
      mutate(forms[0], {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['notification-templates'],
          });

          onSuccess && onSuccess();
        },
      });

      return;
    }

    mutate(forms[0], {
      onSuccess: () => {
        if (type === 'active') {
          queryClient.invalidateQueries({
            queryKey: ['active-notification-campaigns'],
          });
        }

        if (type === 'draft') {
          queryClient.invalidateQueries({
            queryKey: ['draft-notification-campaigns'],
          });
        }

        onSuccess && onSuccess();
      },
    });
  }

  return {
    handleNotificationFormSubmit,
    isPending,
    formRefs,
    forms,
    handleEdit,
  };
}
