import { toTypedSchema } from '@vee-validate/zod';
import { defaultNotificationSchema } from '~/utils/schemas';
import { useAppForm } from '~/composables/useAppForm';

export type FormNotificationErrors = {
  title?: string;
  designation?: string;
  body?: string;
  type?: string;
  is_recurring?: string;
  recurring_type?: string;
  recurring_interval?: string;
  recurring_days_of_week?: string;
  recurring_calendar_days?: string;
  send_for?: string;
  send_user_ids?: string;
  schemat_id?: string;
  end_date?: string;
  start_date?: string;
  start_time?: string;
};

export type DefaultNotificationForm = {
  title: string;
  designation: string;
  body: string;
  type: 'email' | 'sms' | 'push';
  is_recurring: boolean;
  recurring_type?: 'choice_day' | 'quantity_day' | 'calendar_days' | null;
  recurring_interval?: number;
  recurring_days_of_week?: number[] | null;
  recurring_calendar_days?: Date[];
  send_for: 'users' | 'all';
  send_user_ids?: number[] | null;
  schemat_id?: number | null;
  end_date?: string | null;
  start_date?: string | null;
  start_time: Date;
};

export function useDefaultNotificationCampaignForm() {
  const schema = computed(() => toTypedSchema(defaultNotificationSchema));

  const { form, handleSubmit, errors, resetForm, validate } = useAppForm({
    fields: [
      'title',
      'designation',
      'body',
      'type',
      'is_recurring',
      'recurring_type',
      'recurring_interval',
      'recurring_days_of_week',
      'recurring_calendar_days',
      'send_for',
      'send_user_ids',
      'schemat_id',
      'end_date',
      'start_date',
      'start_time',
    ],
    validationSchema: schema,
  });

  function handleResetForm() {
    resetForm();
  }

  handleResetForm();

  return {
    form,
    handleSubmit,
    errors,
    resetForm,
    validate,
  };
}
