<script setup lang="ts">
const { form, errors, validate, resetForm } = useFormNotificationCampaignForm();

defineExpose({
  form,
  validate,
  errors,
  resetForm,
});

const { data: forms } = useAllFormTemplates();
</script>

<template>
  <PrimeAccordionHeader
    class="border text-gray-iron-900"
    :class="{
      'border-red-600': Object.keys(errors).length,
    }"
  >
    <DefaultInput
      class="mr-4"
      v-model="form.designation"
      :error="errors.designation"
      @click.stop
    />
  </PrimeAccordionHeader>

  <PrimeAccordionContent class="bg-gray-iron-100">
    <div class="space-y-4">
      <slot />

      <DefaultSelect
        v-model="form.form_id"
        :options="forms?.data"
        optionValue="id"
        optionLabel="name"
        filter
        placeholder="Wybierz z listy"
        :error="errors.form_id"
      >
        Wybierz formularz
      </DefaultSelect>

      <NotificationMethodPicker v-model:form="form" :errors="errors" />

      <DefaultInput v-model="form.title" :error="errors.title">
        Tytuł
      </DefaultInput>

      <DefaultTextArea v-model="form.body" :error="errors.body">
        Treść
      </DefaultTextArea>
    </div>
  </PrimeAccordionContent>
</template>
