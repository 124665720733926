<script setup lang="ts">
const props = defineProps<{
  campaigns: (NotificationCampaign | NotificationTemplate)[];
  type: 'active' | 'draft' | 'template';
}>();

const expandedRows = ref({} as { [key: number]: boolean });

const { confirmDeleteNotification } = useDeleteNotificationCampaign(props.type);
const { confirmDeleteNotificationTemplate } = useDeleteNotificationTemplate();

function expandTable(id: number) {
  if (expandedRows.value[id]) {
    expandedRows.value = {};

    return;
  }

  expandedRows.value = {
    [id]: true,
  };
}
</script>

<template>
  <PrimeDataTable
    class="primetable"
    v-model:expandedRows="expandedRows"
    :value="campaigns"
    dataKey="id"
  >
    <PrimeColumn field="designation" header="Nazwa"></PrimeColumn>
    <PrimeColumn v-if="type === 'active'">
      <template #body="{ data }: { data: NotificationCampaign }">
        <button
          v-auto-animate
          class="ml-auto flex items-center gap-1 rounded-full border border-gray-iron-300 p-2.5 text-sm font-semibold text-gray-iron-900 outline outline-2 outline-offset-2 transition-colors"
          :class="{
            'outline-primary-600/35': expandedRows[data.id],
            'outline-white': !expandedRows[data.id],
          }"
          :disabled="!data.notification_schedules?.length"
          @click="expandTable(data.id)"
        >
          {{ data.notification_schedules?.length }}
          <i
            class="pi pi-angle-down transition-transform"
            :class="{
              'rotate-180': expandedRows[data.id],
            }"
          />
        </button>
      </template>
    </PrimeColumn>
    <PrimeColumn field="is_recurring" header="Typ">
      <template #body="slotProps">
        <span class="capitalize">
          {{ slotProps.data.is_recurring ? 'Cykliczne' : 'Jednorazowe' }}
        </span>
        {{
      }}</template>
    </PrimeColumn>
    <PrimeColumn field="type" header="Sposób wysyłki">
      <template #body="slotProps">
        <span class="capitalize">
          {{ slotProps.data.type }}
        </span>
      </template>
    </PrimeColumn>

    <PrimeColumn field="schemat" header="Przypisane do schematu">
      <template #body="slotProps">
        {{ slotProps.data.schemat ? slotProps.data.schemat.name : 'Nie' }}
      </template>
    </PrimeColumn>
    <PrimeColumn field="recurring_interval" header="Powtarzaj co">
      <template #body="slotProps">
        <span v-if="slotProps.data.is_recurring">
          {{ formatRecurring(slotProps.data) }}
        </span>
        <span v-else>Nie powtarzaj</span>
      </template>
    </PrimeColumn>
    <PrimeColumn v-if="type === 'active'" field="status" header="Status">
      <template #body="slotProps">
        <PrimeTag
          class="whitespace-nowrap"
          :value="formatDeliveryStatus(slotProps.data.status).text"
          :severity="formatDeliveryStatus(slotProps.data.status).type"
        ></PrimeTag>
      </template>
    </PrimeColumn>
    <PrimeColumn field="actions" header="">
      <template #body="{ data }: { data: NotificationCampaign }">
        <div class="flex">
          <EditNotificationCampaigns :notification="data" :type="type" />

          <PrimeButton
            text
            icon
            severity="warn"
            @click="
              type === 'template'
                ? confirmDeleteNotificationTemplate(data.id)
                : confirmDeleteNotification(data.id)
            "
          >
            <i class="pi pi-trash" />
          </PrimeButton>
        </div>
      </template>
    </PrimeColumn>

    <template #expansion="slotProps">
      <div
        v-if="type === 'active'"
        class="-mx-4 rounded-2xl border border-gray-2 bg-gray-1 p-3"
      >
        <NotificationCampaignUsersTable
          :schedules="slotProps.data.notification_schedules"
        />
      </div>
    </template>
  </PrimeDataTable>
</template>
