import { useQuery } from '@tanstack/vue-query';
import { notificationTemplatesService } from '~/utils/api/notificationTemplatesService';

const PER_PAGE = 10;

export function useNotificationTemplates() {
  const search = ref('');
  const page = ref(0);

  const { data, isLoading } = useQuery({
    queryKey: ['notification-templates', page, search],
    queryFn: () =>
      notificationTemplatesService.getNotificationTemplates(
        search.value,
        page.value / PER_PAGE,
        PER_PAGE,
      ),
  });

  return {
    data,
    search,
    PER_PAGE,
    isLoading,
    page,
  };
}
