<script setup lang="ts">
import type { ModelRef } from 'vue';

defineProps<{
  errors:
    | FormNotificationErrors
    | FormFormNotificationErrors
    | VisitFormNotificationErrors;
}>();

const form: ModelRef<
  DefaultNotificationForm | FormNotificationForm | VisitNotificationForm
> = defineModel('form', {
  type: Object,
  required: true,
});
</script>

<template>
  <div class="space-y-1.5">
    <h2 class="text-sm font-medium text-gray-iron-700">Sposób wysyłki</h2>

    <div class="flex gap-4 text-sm text-gray-iron-700">
      <PrimeRadioButton v-model="form.type" inputId="push" value="push" />
      <label for="push"> Push </label>

      <PrimeRadioButton v-model="form.type" inputId="email" value="email" />
      <label for="email"> E-mail </label>

      <PrimeRadioButton
        v-model="form.type"
        inputId="email_push"
        value="email_push"
      />
      <label for="email_push"> E-mail i Push </label>
    </div>

    <small class="text-left text-xs text-red-600">
      {{ errors.type }}
    </small>
  </div>
</template>
