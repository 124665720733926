<script setup lang="ts">
const {
  data: templates,
  search,
  PER_PAGE,
  isLoading,
  page,
} = useNotificationTemplates();
</script>

<template>
  <div>
    <div class="mb-6 flex items-center justify-between">
      <div class="flex gap-4">
        <PrimeIconField>
          <PrimeInputIcon class="pi pi-search" />
          <PrimeInputText v-model="search" placeholder="Szukaj" />
        </PrimeIconField>
      </div>

      <AddNotificationCampaign />
    </div>

    <NotificationCampaignsTable
      v-if="templates?.data?.length"
      type="template"
      :campaigns="templates?.data"
    />

    <div v-else-if="!isLoading" class="text-base text-gray-iron-900">
      Nie znalezniono szablonów
    </div>

    <PrimePaginator
      v-if="Number(templates?.meta.total) > 10"
      v-model:first="page"
      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      :rows="PER_PAGE"
      :totalRecords="templates?.meta.total"
    />
  </div>
</template>
