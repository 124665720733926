import { toTypedSchema } from '@vee-validate/zod';
import { visitNotificationSchema } from '~/utils/schemas';
import { useAppForm } from '~/composables/useAppForm';

export type VisitFormNotificationErrors = {
  title?: string;
  designation?: string;
  body?: string;
  type?: string;
  form_id?: string;
  schemat_id?: string;
  quantity_days?: string;
};

export type VisitNotificationForm = {
  title: string;
  designation: string;
  body: string;
  type: 'email' | 'email_push' | 'push';
  schemat_id: number;
  quantity_days: number;
};

export function useVisitNotificationCampaignForm() {
  const schema = computed(() => toTypedSchema(visitNotificationSchema));

  const { form, handleSubmit, errors, resetForm, validate } = useAppForm({
    fields: [
      'title',
      'designation',
      'body',
      'type',
      'schemat_id',
      'quantity_days',
    ],
    validationSchema: schema,
  });

  function handleResetForm() {
    resetForm();
  }

  handleResetForm();

  return {
    form,
    handleSubmit,
    errors,
    resetForm,
    validate,
  };
}
