<script setup lang="ts">
import type { ModelRef } from 'vue';

defineProps<{
  errors: FormNotificationErrors;
}>();

const form: ModelRef<DefaultNotificationForm> = defineModel('form', {
  type: Object,
  required: true,
});

const { data: patients } = useAllPatients();
const { activeSchemats } = useSchemats();

function handleResetSendFor() {
  form.value.send_user_ids = undefined;
  form.value.schemat_id = undefined;
}
</script>

<template>
  <div class="space-y-1.5 text-sm font-medium text-gray-iron-700">
    <h2>Wyślij do</h2>

    <div class="flex flex-wrap gap-4 text-sm">
      <PrimeRadioButton
        v-model="form.send_for"
        inputId="users"
        value="users"
        @update:modelValue="handleResetSendFor"
      />
      <label for="users">Wybranego pacjenta </label>

      <PrimeRadioButton
        v-model="form.send_for"
        inputId="all"
        value="all"
        @update:modelValue="handleResetSendFor"
      />
      <label for="all">Wszystkich w schemacie</label>
    </div>

    <small class="text-left text-xs text-red-600">
      {{ errors.send_for }}
    </small>
  </div>

  <DefaultMultiselect
    v-if="form.send_for === 'users'"
    v-model="form.send_user_ids"
    display="chip"
    :options="patients"
    optionValue="id"
    optionLabel="full_name"
    filter
    placeholder="Wybierz z listy"
    :error="errors.send_user_ids"
  >
    Pacjent
  </DefaultMultiselect>

  <DefaultSelect
    v-if="form.send_for === 'all'"
    v-model="form.schemat_id"
    :options="activeSchemats"
    optionValue="id"
    optionLabel="name"
    filter
    placeholder="Wybierz z listy"
    :error="errors.schemat_id"
  >
    Wybierz schemat
  </DefaultSelect>
</template>
