<script setup lang="ts">
const selectedDays = defineModel('selectedDays', {
  type: Array<Date>,
  required: false,
});

function removeDay(day: Date) {
  console.log('remove', day);
  selectedDays.value = selectedDays.value?.filter((d) => d !== day);
}
</script>

<template>
  <div>
    <div class="flex gap-2 pb-3">
      <span
        class="cursor-pointer select-none rounded-full border border-[#E4E7EC] bg-[#F9FAFB] px-2 py-0.5 text-xs"
        v-for="(day, index) in selectedDays"
        :key="'selected-day-' + index"
        severity="secondary"
        @click="removeDay(day)"
      >
        {{ formatDateToShortDayAndMonth(day) }}
        <i class="pi pi-times text-[10px]"></i>
      </span>
    </div>

    <PrimeCalendar
      v-model="selectedDays"
      class="w-full"
      inline
      selectionMode="multiple"
    />
  </div>
</template>
