import { useQuery } from '@tanstack/vue-query';
import { notificationCampaignsService } from '~/utils/api/notificationCampaignsService';

const PER_PAGE = 10;

export function useNotificationCampaigns(type: 'active' | 'draft') {
  const search = ref('');
  const page = ref(0);

  watch(search, () => {
    page.value = 0;
  });

  const { data, isLoading } = useQuery({
    queryKey: [`${type}-notification-campaigns`, page, search],
    queryFn: () =>
      notificationCampaignsService.getNotificationCampaigns(
        type === 'active',
        search.value,
        page.value / PER_PAGE,
        PER_PAGE,
      ),
  });

  return {
    data,
    search,
    PER_PAGE,
    isLoading,
    page,
  };
}
