import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useConfirm } from 'primevue/useconfirm';
import { notificationCampaignsService } from '@/utils/api/notificationCampaignsService';

export function useDeleteNotificationCampaign(
  type: 'active' | 'draft' | 'template',
) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const confirm = useConfirm();

  function confirmDeleteNotification(id: number) {
    confirm.require({
      message: 'Czy na pewno chcesz usunąć powiadomienie?',
      header: ' ',
      rejectLabel: 'Anuluj',
      rejectProps: {
        label: 'Anuluj',
        severity: 'secondary',
        outlined: true,
        size: 'small',
        rounded: true,
      },
      acceptProps: {
        label: 'Usuń',
        severity: 'danger',
        size: 'small',
        rounded: true,
      },
      accept: () => {
        deleteNotificationCampaign(id);
      },
    });
  }

  const { mutate } = useMutation({
    mutationFn: (id: number) =>
      notificationCampaignsService.deleteNotificationCampaign(id),
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas usuwania kampanii powiadomień',
        life: 3000,
      });

      queryClient.invalidateQueries({
        queryKey: [`${type}-notification-campaigns`],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`${type}-notification-campaigns`],
      });
    },
  });

  function deleteNotificationCampaign(id: number) {
    mutate(id);
  }

  return {
    confirmDeleteNotification,
  };
}
